import React from "react";

function Services() {
    return (
    <section id="services" class="services">
    <div class="container" style={{ height: '540px' }}>
      <div class="section-title">
        <h2>学习小工具</h2>
        <p>这里是一些我的学习小工具。</p>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
          <div class="icon"><i class="bi bi-card-checklist"></i></div>
          <h4 class="title"><a href="mathpractise/print.html">打印口算单</a></h4>
          <p class="description">每天练一遍，你也来一遍。</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
          <div class="icon"><i class="bi bi-binoculars"></i></div>
          <h4 class="title"><a href="preview.png">打印预习单</a></h4>
          <p class="description">预习使我学的更好</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
          <div class="icon"><i class="bi bi-brightness-high"></i></div>
          <h4 class="title"><a href="pinyin_format1.jpg">打印拼音单</a></h4>
          <p class="description">b p m f我最棒！</p>
        </div>
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
          <div class="icon"><i class="bi bi-table"></i></div>
          <h4 class="title"><a href="https://hldgaofeng.github.io/oralcalc/">口算生成器</a></h4>
          <p class="description">By 高大科技</p>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Services;