
import "aos/dist/aos.css";
import React, { useState, useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "./resource/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "boxicons/css/boxicons.css";
import 'boxicons';
//import "boxicons/dist/boxicons"
import "glightbox/dist/css/glightbox.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import FaultDatabase from "./pages/faultdatabase/faultdatabase";
import Services from "./pages/services/services";
import initPage from "./util/main.js";
// TODO 有问题，临时删除
//const FaultHome = lazy(() => import('./pages/faultdatabase/home.js'));
// 增加代码到Route              <Route path="/fault" element={<FaultHome />} />

//import mainjs from "./util/main.js";

import ProfileImg from "./resource/img/profile-img.jpg";


const Init = () => {
  const initScript = () => {
    initPage();
  }
  useEffect(() => {
    initScript()
  }, [])
  return null
}

function App() {
  return (
    <div className="App">
      <i class="bi bi-list mobile-nav-toggle d-xl-none"></i>
      <header id="header">
        <div class="d-flex flex-column">
          <div class="profile">
            <img src={ProfileImg} alt="" class="img-fluid rounded-circle" />
            <h1 class="text-light"><a href="index.html">沈空以<br />Sky </a></h1>
          </div>
          <nav id="navbar" class="nav-menu navbar">
            <ul>
              <li><a href="#hero" class="nav-link scrollto active"><i class="bx bx-home"></i> <span>Home</span></a></li>
              <li><a href="#services" class="nav-link scrollto"><i class="bx bx-server"></i> <span>学习小工具</span></a></li>
              <li><a href="#faultdatabase" class="nav-link scrollto"><i class="bx bx-database"></i> <span>错题本</span></a></li>
            </ul>
          </nav>
        </div>
      </header>
      <section id="hero" class="d-flex flex-column justify-content-center align-items-center">
        <div class="hero-container" data-aos="fade-in">
          <h1>沈 空以</h1>
          <p>我是 <span class="typed" data-typed-items="街舞舞者, 围棋棋手, 乐高er, 英语小达人"></span></p>
        </div>
      </section>
      <main id="main">
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Services />} />
              <Route path="/" element={<FaultDatabase />} />
            </Routes>
          </Suspense>
        </Router>
      </main>
      <footer id="footer">
        <div class="container">
          <div class="copyright">
            &copy; Copyright <strong><span>Sky</span></strong>
          </div>
          <div class="credits">
            Designed by <a href="https://skyshen.co.jp/">Sky and His Daddy</a>
          </div>
        </div>
      </footer>
      <a href="#" class="back-to-top d-flex align-items-center justify-content-center">
        <i class="bi bi-arrow-up-short"></i>
      </a>
      <Init />
    </div>
  );
}

export default App;
